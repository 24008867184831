import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchMenuDetails = createAsyncThunk(
  "admin/menu-details",
  async ({ menuID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.menuDetailsURL.replace("{id}", menuID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const menuDetailsSlice = createSlice({
  name: "menu-details",
  initialState: {
    menuDetails: {},
    menuDetailsFetching: false,
    menuDetailsSuccess: false,
    menuDetailsError: false,
    menuDetailsErrorMessage: "",
  },
  reducers: {
    clearMenuDetailsState: (state) => {
      state.menuDetailsError = false;
      state.menuDetailsSuccess = false;
      state.menuDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchMenuDetails.fulfilled]: (state, { payload }) => {
      state.menuDetails = payload.data;

      state.menuDetailsFetching = false;
      state.menuDetailsSuccess = true;
      return state;
    },
    [fetchMenuDetails.rejected]: (state, action) => {
      state.menuDetailsFetching = false;
      state.menuDetailsError = true;
      state.menuDetailsErrorMessage = action?.payload;
    },
    [fetchMenuDetails.pending]: (state) => {
      state.menuDetailsFetching = true;
    },
  },
});
export const { clearMenuDetailsState } = menuDetailsSlice.actions;

export default menuDetailsSlice.reducer;
