import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import settingReducer from "./setting/reducers";

//Login
import userSlice from "./login/loginSlice";
import forgotPasswordSlice from "./login/forgotPasswordSlice";
import sendOtpSlice from "./login/sendOtpSlice";
import verifyOtpSlice from "./login/verifyOtpSlice";
// import userProfileSlice from "./profile/userProfileSlice";

//Menus
import menuListSlice from "./menus/menuListSlice";
import createMenuSlice from "./menus/createMenuSlice";
import menuDetailsSlice from "./menus/menuDetailsSlice";
import editMenuSlice from "./menus/editMenuSlice";
import deleteMenuSlice from "./menus/deleteMenuSlice";
import menuSettingDetailsSlice from "./menus/menuSettingDetailsSlice";
import editMenuSettingSettingSlice from "./menus/editMenuSettingSlice";
import ratingListSlice from "./menus/submenus/ratingListSlice";
import changeAvailabilitySlice from "./menus/changeAvailabilitySlice";

//SubMenus
import subMenuListSlice from "./menus/submenus/subMenuListSlice";
import subMenuDetailsSlice from "./menus/submenus/subMenuDetailsSlice";
import createSubMenuSlice from "./menus/submenus/createSubMenuSlice";
import editSubMenuSlice from "./menus/submenus/editSubMenuSlice";
import deleteSubMenuSlice from "./menus/submenus/deleteSubMenuSlice";

//Categories
import categoryListSlice from "./categories/categoryListSlice";
import createCategorySlice from "./categories/createCategorySlice";
import categoryDetailsSlice from "./categories/categoryDetailsSlice";
import editCategorySlice from "./categories/editCategorySlice";
import deleteCategorySlice from "./categories/deleteCategorySlice";

//Feedbacks
import feedbackListSlice from "./Feedbacks/feedbackListSlice";

//Settings
import businessDetailsSlice from "./systemSettings/business/businessDetailsListSlice";
import businessEditSlice from "./systemSettings/business/businessDetailsEditSlice";
import workingDaysListSlice from "./systemSettings/workingDays/workingDaysListSlice";
import workingDayDetailsSlice from "./systemSettings/workingDays/workingDayDetailsSlice";
import workingDayEditSlice from "./systemSettings/workingDays/workingDayEditSlice";
import imagesListSlice from "./systemSettings/Images/imagesListSlice";
import createImageSlice from "./systemSettings/Images/createImageSlice";

//Banner Images
import bannerImagesListSlice from "./systemSettings/bannerImages/bannerImagesListSlice";
import createBannerImageSlice from "./systemSettings/bannerImages/createBannerImageSlice";
import editBannerImageSlice from "./systemSettings/bannerImages/editBannerImageSlice";
import deleteBannerImageSlice from "./systemSettings/bannerImages/deleteBannerImageSlice";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    setting: settingReducer,
    userSlice,
    forgotPasswordSlice,
    sendOtpSlice,
    verifyOtpSlice,
    //Menus
    menuListSlice,
    createMenuSlice,
    menuDetailsSlice,
    editMenuSlice,
    deleteMenuSlice,
    menuSettingDetailsSlice,
    editMenuSettingSettingSlice,
    ratingListSlice,
    changeAvailabilitySlice,
    //SubMenus
    subMenuListSlice,
    subMenuDetailsSlice,
    createSubMenuSlice,
    editSubMenuSlice,
    deleteSubMenuSlice,
    //Categories
    categoryListSlice,
    createCategorySlice,
    categoryDetailsSlice,
    editCategorySlice,
    deleteCategorySlice,
    //Feedbacks
    feedbackListSlice,
    //Settings
    businessDetailsSlice,
    businessEditSlice,
    workingDaysListSlice,
    workingDayDetailsSlice ,
    workingDayEditSlice,
    imagesListSlice,
    createImageSlice,
    bannerImagesListSlice,
    createBannerImageSlice,
    editBannerImageSlice,
    deleteBannerImageSlice,
  },
});
