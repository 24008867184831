import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";


export const fetchWorkingDaysList = createAsyncThunk(
  "admin/working-days-list",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.workingDaysURL,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const workingDaysListSlice = createSlice({
  name: "working-days-list",
  initialState: {
    workingDaysList: [],
    workingDaysListFetching: false,
    workingDaysListSuccess: false,
    workingDaysListError: false,
    workingDaysListErrorMessage: "",
    workingDaysCount: null,
  },
  reducers: {
    workingDaysListClearState: (state) => {
      state.workingDaysListError = false;
      state.workingDaysListSuccess = false;
      state.workingDaysListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchWorkingDaysList.fulfilled]: (state, { payload }) => {
      state.workingDaysList = [];
      payload.data.results.forEach((item) => state.workingDaysList.push(item));
      state.workingDaysCount = payload.data.count;
      state.workingDaysListFetching = false;
      state.workingDaysListSuccess = true;
      return state;
    },
    [fetchWorkingDaysList.rejected]: (state, action) => {
      state.workingDaysListFetching = false;
      state.workingDaysListError = true;
      state.workingDaysListErrorMessage = action?.payload;
    },
    [fetchWorkingDaysList.pending]: (state) => {
      state.workingDaysListFetching = true;
    },
  },
});

export const { workingDaysListClearState } = workingDaysListSlice.actions;

export default workingDaysListSlice.reducer;
