import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const editBannerImage = createAsyncThunk(
  "admin/banner-image-edit",
  async ({payload, imageID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.bannerImageDetailsURL.replace("{id}", imageID),
        method: "PATCH",
        data:payload
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const editBannerImageSlice = createSlice({
  name: "banner-image-edit",
  initialState: {
    editBannerImageFetching: false,
    editBannerImageSuccess: false,
    editBannerImageError: false,
    editBannerImageErrorMessage: "",
  },
  reducers: {
    clearEditBannerImageState: (state) => {
      state.editBannerImageError = false;
      state.editBannerImageSuccess = false;
      state.editBannerImageFetching = false;

      return state;
    },
  },
  extraReducers: {
    [editBannerImage.fulfilled]: (state, { payload }) => {
      state.editBannerImageFetching = false;
      state.editBannerImageSuccess = true;
      return state;
    },
    [editBannerImage.rejected]: (state, action) => {
      state.editBannerImageFetching = false;
      state.editBannerImageError = true;
      state.editBannerImageErrorMessage = action?.payload;
    },
    [editBannerImage.pending]: (state) => {
      state.editBannerImageFetching = true;
    },
  },
});
export const { clearEditBannerImageState } = editBannerImageSlice.actions;

export default editBannerImageSlice.reducer;
