import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const deleteBannerImage = createAsyncThunk(
  "admin/delete-banner-image",
  async ({ imageID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.bannerImageDetailsURL.replace("{id}", imageID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const deleteBannerImageSlice = createSlice({
  name: "delete-banner-image",
  initialState: {
    deleteBannerImageFetching: false,
    deleteBannerImageSuccess: false,
    deleteBannerImageError: false,
    deleteBannerImageErrorMessage: "",
  },
  reducers: {
    deleteBannerImageClearState: (state) => {
      state.deleteBannerImageError = false;
      state.deleteBannerImageSuccess = false;
      state.deleteBannerImageFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteBannerImage.fulfilled]: (state, { payload }) => {
      state.deleteBannerImageFetching = false;
      state.deleteBannerImageSuccess = true;
      return state;
    },
    [deleteBannerImage.rejected]: (state, action) => {
      state.deleteBannerImageFetching = false;
      state.deleteBannerImageError = true;
      state.deleteBannerImageErrorMessage = action?.payload;
    },
    [deleteBannerImage.pending]: (state) => {
      state.deleteBannerImageFetching = true;
    },
  },
});

export const { deleteBannerImageClearState } = deleteBannerImageSlice.actions;

export default deleteBannerImageSlice.reducer;
