import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchSubMenuList = createAsyncThunk(
  "admin/sub-menu-list",
  async ({ limit, offset ,menuID  }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.submenuListURL.replace("{id}", menuID).replace("{limit}", limit).replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const subMenuListSlice = createSlice({
  name: "sub-menu-list",
  initialState: {
    subMenuList: [],
    subMenuListFetching: false,
    subMenuListSuccess: false,
    subMenuListError: false,
    subMenuListErrorMessage: "",
    subMenusCount: null,
  },
  reducers: {
    subMenuListClearState: (state) => {
      state.subMenuListError = false;
      state.subMenuListSuccess = false;
      state.subMenuListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchSubMenuList.fulfilled]: (state, { payload }) => {
      state.subMenuList = [];
      payload.data.results.forEach((item) => state.subMenuList.push(item));
      state.subMenusCount = payload.data.count;
      state.subMenuListFetching = false;
      state.subMenuListSuccess = true;
      return state;
    },
    [fetchSubMenuList.rejected]: (state, action) => {
      state.subMenuListFetching = false;
      state.subMenuListError = true;
      state.subMenuListErrorMessage = action?.payload;
    },
    [fetchSubMenuList.pending]: (state) => {
      state.subMenuListFetching = true;
    },
  },
});

export const { subMenuListClearState } = subMenuListSlice.actions;

export default subMenuListSlice.reducer;
