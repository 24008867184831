import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchRatingsList = createAsyncThunk(
  "admin/rating-list",
  async ({ limit, offset ,menuID  }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.menuRatingListURL.replace("{id}", menuID).replace("{limit}", limit).replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const ratingListSlice = createSlice({
  name: "rating-list",
  initialState: {
    ratingList: [],
    ratingListFetching: false,
    ratingListSuccess: false,
    ratingListError: false,
    ratingListErrorMessage: "",
    ratingsCount: null,
  },
  reducers: {
    ratingListClearState: (state) => {
      state.ratingListError = false;
      state.ratingListSuccess = false;
      state.ratingListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchRatingsList.fulfilled]: (state, { payload }) => {
      state.ratingList = [];
      payload.data.results.forEach((item) => state.ratingList.push(item));
      state.ratingsCount = payload.data.count;
      state.ratingListFetching = false;
      state.ratingListSuccess = true;
      return state;
    },
    [fetchRatingsList.rejected]: (state, action) => {
      state.ratingListFetching = false;
      state.ratingListError = true;
      state.ratingListErrorMessage = action?.payload;
    },
    [fetchRatingsList.pending]: (state) => {
      state.ratingListFetching = true;
    },
  },
});

export const { ratingListClearState } = ratingListSlice.actions;

export default ratingListSlice.reducer;
