import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchMenuSettingDetails = createAsyncThunk(
  "admin/menu-setting-details",
  async ({ menuID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.menuSettingURL.replace("{id}", menuID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const menuSettingDetailsSlice = createSlice({
  name: "menu-setting-details",
  initialState: {
    menuSettingDetails: {},
    menuSettingDetailsFetching: false,
    menuSettingDetailsSuccess: false,
    menuSettingDetailsError: false,
    menuSettingDetailsErrorMessage: "",
  },
  reducers: {
    clearMenuSettingDetailsState: (state) => {
      state.menuSettingDetailsError = false;
      state.menuSettingDetailsSuccess = false;
      state.menuSettingDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchMenuSettingDetails.fulfilled]: (state, { payload }) => {
      state.menuSettingDetails = payload.data;

      state.menuSettingDetailsFetching = false;
      state.menuSettingDetailsSuccess = true;
      return state;
    },
    [fetchMenuSettingDetails.rejected]: (state, action) => {
      state.menuSettingDetailsFetching = false;
      state.menuSettingDetailsError = true;
      state.menuSettingDetailsErrorMessage = action?.payload;
    },
    [fetchMenuSettingDetails.pending]: (state) => {
      state.menuSettingDetailsFetching = true;
    },
  },
});
export const { clearMenuSettingDetailsState } = menuSettingDetailsSlice.actions;

export default menuSettingDetailsSlice.reducer;
