import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchBannerImagesList = createAsyncThunk(
  "admin/banner-image-list",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.bannerImageURL,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const bannerImagesListSlice = createSlice({
  name: "banner-image-list",
  initialState: {
    bannerImagesList: [],
    bannerImagesListFetching: false,
    bannerImagesListSuccess: false,
    bannerImagesListError: false,
    bannerImagesListErrorMessage: "",

  },
  reducers: {
    bannerImagesListClearState: (state) => {
      state.bannerImagesListError = false;
      state.bannerImagesListSuccess = false;
      state.bannerImagesListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchBannerImagesList.fulfilled]: (state, { payload }) => {
      state.bannerImagesList = [];
      payload.data.results.forEach((item) => state.bannerImagesList.push(item));
      state.bannerImagesListFetching = false;
      state.bannerImagesListSuccess = true;
      return state;
    },
    [fetchBannerImagesList.rejected]: (state, action) => {
      state.bannerImagesListFetching = false;
      state.bannerImagesListError = true;
      state.bannerImagesListErrorMessage = action?.payload;
    },
    [fetchBannerImagesList.pending]: (state) => {
      state.bannerImagesListFetching = true;
    },
  },
});

export const { bannerImagesListClearState } = bannerImagesListSlice.actions;

export default bannerImagesListSlice.reducer;
