import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const createCategory = createAsyncThunk(
  "admin/create-category",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.categoryPostURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const createCategorySlice = createSlice({
  name: "create-category",
  initialState: {
    createCategoryFetching: false,
    createCategorySuccess: false,
    createCategoryError: false,
    createCategoryErrorMessage: "",
  },
  reducers: {
    clearCreateCategoryState: (state) => {
      state.createCategoryError = false;
      state.createCategorySuccess = false;
      state.createCategoryFetching = false;

      return state;
    },
  },
  extraReducers: {
    [createCategory.fulfilled]: (state, { payload }) => {
      state.createCategoryFetching = false;
      state.createCategorySuccess = true;
      return state;
    },
    [createCategory.rejected]: (state, action) => {
      state.createCategoryFetching = false;
      state.createCategoryError = true;
      state.createCategoryErrorMessage = action?.payload;
    },
    [createCategory.pending]: (state) => {
      state.createCategoryFetching = true;
    },
  },
});

export const { clearCreateCategoryState } = createCategorySlice.actions;

export default createCategorySlice.reducer;
