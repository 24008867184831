import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const subMenuEdit = createAsyncThunk(
  "admin/sub-menu-edit",
  async ({payload, submenuID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.submenuDetailsURL.replace("{id}", submenuID),
        method: "PATCH",
        data:payload
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const editSubMenuSlice = createSlice({
  name: "sub-menu-edit",
  initialState: {
    editSubMenuFetching: false,
    editSubMenuSuccess: false,
    editSubMenuError: false,
    editSubMenuErrorMessage: "",
  },
  reducers: {
    clearEditSubMenuState: (state) => {
      state.editSubMenuError = false;
      state.editSubMenuSuccess = false;
      state.editSubMenuFetching = false;

      return state;
    },
  },
  extraReducers: {
    [subMenuEdit.fulfilled]: (state, { payload }) => {
      state.editSubMenuFetching = false;
      state.editSubMenuSuccess = true;
      return state;
    },
    [subMenuEdit.rejected]: (state, action) => {
      state.editSubMenuFetching = false;
      state.editSubMenuError = true;
      state.editSubMenuErrorMessage = action?.payload;
    },
    [subMenuEdit.pending]: (state) => {
      state.editSubMenuFetching = true;
    },
  },
});
export const { clearEditSubMenuState } = editSubMenuSlice.actions;

export default editSubMenuSlice.reducer;
