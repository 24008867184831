import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";


export const fetchWorkingDayDetails = createAsyncThunk(
  "admin/working-day-details",
  async ({ workingDayID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.workingDaysDetailsURL.replace("{id}", workingDayID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const workingDayDetailsSlice = createSlice({
  name: "working-day-details",
  initialState: {
    workingDayDetails: {},
    workingDayDetailsFetching: false,
    workingDayDetailsSuccess: false,
    workingDayDetailsError: false,
    workingDayDetailsErrorMessage: "",
  },
  reducers: {
    clearWorkingDayDetailsState: (state) => {
      state.workingDayDetailsError = false;
      state.workingDayDetailsSuccess = false;
      state.workingDayDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchWorkingDayDetails.fulfilled]: (state, { payload }) => {
      state.workingDayDetails = payload.data;

      state.workingDayDetailsFetching = false;
      state.workingDayDetailsSuccess = true;
      return state;
    },
    [fetchWorkingDayDetails.rejected]: (state, action) => {
      state.workingDayDetailsFetching = false;
      state.workingDayDetailsError = true;
      state.workingDayDetailsErrorMessage = action?.payload;
    },
    [fetchWorkingDayDetails.pending]: (state) => {
      state.workingDayDetailsFetching = true;
    },
  },
});
export const { clearWorkingDayDetailsState } = workingDayDetailsSlice.actions;

export default workingDayDetailsSlice.reducer;
