import axios from "axios";

const actionHandler = (payload) => {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Cache-Control"] = "no-cache";

  const token = localStorage.getItem("admin-token");
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }

  return new Promise((resolve, reject) => {
    payload.baseURL = "https://bdine.enfono.com/api_bdine_backend/api/";

    axios(payload)
      .then((response) => {
        let resp = response.data;
        if (response.status >= 200 && response.status < 300) {
          resolve(response);
        } else {
 
          reject(response);
        }
      })
      .catch((err) => {

        reject(err);
      });
  });
};


axios.interceptors.response.use(undefined, function (err) {
  var statusCode = err.status;
  if (statusCode == undefined) {
    // Server needs to specify CORS headers in the response
    // Basically `ACCESS-CONTROL-ALLOW-ORIGIN: *`
    // Otherwise, these kinda issues happen

    var lineSplit = err.toString().split("\n")[0].split(" ");
    statusCode = lineSplit[lineSplit.length - 1];
  }


  return new Promise(() => {
    if (statusCode == 401 && err.config && !err.config.__isRetryRequest) {
      // Got an unauthorized, logout the staff
      localStorage.removeItem("admin-token");
      window.location.pathname = "/auth/sign-in";
      
    }
    throw err;
  });
});

export default {
  /* auth URLs */
  loginURL: "auth/login/", // [POST]
  logoutURL: "auth/logout/", // [POST]
  profileURL: "auth/profile/", // [GET, PUT]
  forgotPasswordURL: "auth/forgot_password/{id}/reset/", // [PUT]
  sendOtpURL: "auth/forgot_password/otp_send/", // [POST]
  verifyOtpURL: "auth/forgot_password/otp_verify/", // [POST]

  //Menus
  menuListURL: "restaurant/menus/?limit={limit}&offset={offset}&search={search}", //[GET]
  menuPostURL: "restaurant/menus/", //[POST]
  menuDetailsURL: "restaurant/menu/{id}/", //[GET, PATCH, DELETE]
  menuSettingURL: 'restaurant/menu/{id}/settings/',//[GET, PATCH]
  menuRatingListURL: 'restaurant/menu/{id}/ratings/?limit={limit}&offset={offset}',
  menuRatingOverviewURL: 'restaurant/menu/{id}/rating/overview/',
  changeAvailabilityURL: 'restaurant/menu/{id}/is_available',

  //Submenus
  submenuListURL: "restaurant/menu/{id}/submenus/?limit={limit}&offset={offset}", //[GET]
  submenuPostURL: "restaurant/menu/{id}/submenus/", //[POST]
  submenuDetailsURL: "restaurant/submenu/{id}/", //[GET, PATCH, DELETE]

  //Categories
  categoryListURL: "restaurant/categories/?limit={limit}&offset={offset}", //[GET]
  categoryPostURL: "restaurant/categories/", //[POST]
  categoryDetailsURL: "restaurant/category/{id}/", //[GET, PATCH, DELETE]
  
  //Feedbacks
  feedbackListURL: "restaurant/feedbacks/?limit={limit}&offset={offset}", //[GET]

  //Settings
  organizationDetailsURL: "web_admin/organization/", //[GET, PATCH]
  workingDaysURL: 'restaurant/working_days/',
  workingDaysDetailsURL: 'restaurant/working_day/{id}/',
  logoImageURL: "restaurant/profile_picture", //[GET],
  bannerImageURL: "restaurant/banner_images/", //[GET],
  bannerImageDetailsURL: "restaurant/banner_image/{id}/", //[GET, PATCH, DELETE]
  


  actionHandler,
};
