import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const createSubMenu = createAsyncThunk(
  "admin/create-sub-menu",
  async ({ payload ,menuID}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.submenuPostURL.replace("{id}", menuID),
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const createSubMenuSlice = createSlice({
  name: "create-sub-menu",
  initialState: {
    createSubMenuFetching: false,
    createSubMenuSuccess: false,
    createSubMenuError: false,
    createSubMenuErrorMessage: "",
  },
  reducers: {
    clearCreateSubMenuState: (state) => {
      state.createSubMenuError = false;
      state.createSubMenuSuccess = false;
      state.createSubMenuFetching = false;

      return state;
    },
  },
  extraReducers: {
    [createSubMenu.fulfilled]: (state, { payload }) => {
      state.createSubMenuFetching = false;
      state.createSubMenuSuccess = true;
      return state;
    },
    [createSubMenu.rejected]: (state, action) => {
      state.createSubMenuFetching = false;
      state.createSubMenuError = true;
      state.createSubMenuErrorMessage = action?.payload;
    },
    [createSubMenu.pending]: (state) => {
      state.createSubMenuFetching = true;
    },
  },
});

export const { clearCreateSubMenuState } = createSubMenuSlice.actions;

export default createSubMenuSlice.reducer;
