import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchImagesList = createAsyncThunk(
  "admin/images-list",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.logoImageURL,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const imagesListSlice = createSlice({
  name: "images-list",
  initialState: {
    imagesList: {},
    imagesListFetching: false,
    imagesListSuccess: false,
    imagesListError: false,
    imagesListErrorMessage: "",
    imagesCount: null,
  },
  reducers: {
    imagesListClearState: (state) => {
      state.imagesListError = false;
      state.imagesListSuccess = false;
      state.imagesListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchImagesList.fulfilled]: (state, { payload }) => {
      state.imagesList = {};
      state.imagesList = payload.data
      //   payload.data.results.forEach((item) => state.imagesList.push(item));
      state.imagesCount = payload.data.count;
      state.imagesListFetching = false;
      state.imagesListSuccess = true;
      return state;
    },
    [fetchImagesList.rejected]: (state, action) => {
      state.imagesListFetching = false;
      state.imagesListError = true;
      state.imagesListErrorMessage = action?.payload;
    },
    [fetchImagesList.pending]: (state) => {
      state.imagesListFetching = true;
    },
  },
});

export const { imagesListClearState } = imagesListSlice.actions;

export default imagesListSlice.reducer;
