import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchFeedbackList = createAsyncThunk(
  "admin/feedback-list",
  async ({ limit, offset }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.feedbackListURL.replace("{limit}", limit).replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const feedbackListSlice = createSlice({
  name: "feedback-list",
  initialState: {
    feedbackList: [],
    feedbackListFetching: false,
    feedbackListSuccess: false,
    feedbackListError: false,
    feedbackListErrorMessage: "",
    feedbacksCount: null,
  },
  reducers: {
    feedbackListClearState: (state) => {
      state.feedbackListError = false;
      state.feedbackListSuccess = false;
      state.feedbackListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchFeedbackList.fulfilled]: (state, { payload }) => {
      state.feedbackList = [];
      payload.data.results.forEach((item) => state.feedbackList.push(item));
      state.feedbacksCount = payload.data.count;
      state.feedbackListFetching = false;
      state.feedbackListSuccess = true;
      return state;
    },
    [fetchFeedbackList.rejected]: (state, action) => {
      state.feedbackListFetching = false;
      state.feedbackListError = true;
      state.feedbackListErrorMessage = action?.payload;
    },
    [fetchFeedbackList.pending]: (state) => {
      state.feedbackListFetching = true;
    },
  },
});

export const { feedbackListClearState } = feedbackListSlice.actions;

export default feedbackListSlice.reducer;
