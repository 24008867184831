import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const changeAvailability = createAsyncThunk(
  "admin/changeAvailability",
  async ({ payload ,menuID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.changeAvailabilityURL.replace("{id}", menuID),
        method: "PUT",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const changeAvailabilitySlice = createSlice({
  name: "changeAvailability",
  initialState: {
    changeAvailabilityFetching: false,
    changeAvailabilitySuccess: false,
    changeAvailabilityError: false,
    changeAvailabilityErrorMessage: "",
  },
  reducers: {
    clearChangeAvailabilityState: (state) => {
      state.changeAvailabilityError = false;
      state.changeAvailabilitySuccess = false;
      state.changeAvailabilityFetching = false;

      return state;
    },
  },
  extraReducers: {
    [changeAvailability.fulfilled]: (state, { payload }) => {
      state.changeAvailabilityFetching = false;
      state.changeAvailabilitySuccess = true;
      return state;
    },
    [changeAvailability.rejected]: (state, action) => {
      state.changeAvailabilityFetching = false;
      state.changeAvailabilityError = true;
      state.changeAvailabilityErrorMessage = action?.payload;
    },
    [changeAvailability.pending]: (state) => {
      state.changeAvailabilityFetching = true;
    },
  },
});

export const { clearChangeAvailabilityState } = changeAvailabilitySlice.actions;

export default changeAvailabilitySlice.reducer;
